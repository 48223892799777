.Home {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	font-size: 24px;
	text-align: center;
	line-height: 1.4;
	background: white;
}
.Home__logo {
	margin: 20px auto;
	max-width: 60%;
}
.Home h1 {
	text-align: center;
	font-size: 36px;
	font-weight: bold;
	margin-top: 2em;
}
.Home h2 {
	margin-bottom: auto;
	margin-top: 1em;
	font-size: 30px;
}
.Home a {
	text-decoration: none;
	color: inherit;
	display: inline-block;
	padding: 0.75em 1.75em;
	border-radius: 1em;
	box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
	background: var(--yellow);
	letter-spacing: 0.15em;
	font-weight: bold;
}
.Home p {
	max-width: 90%;
	margin: 1em auto;
	font-size: 18px;
}
.Home__showing {
	margin-bottom: 50px;
}
