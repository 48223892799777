.RtpPage {
	display: flex;
	flex-direction: column;
}


.Film {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: stretch;
	justify-content: center;
}

.Film__player {
	margin: auto auto 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.Film__title {
	margin: 0.5em auto 0;
	line-height: 0.8em;
	font-size: 65px;
	font-weight: 500;
}
.Film__onSync {
	margin-top: auto;
	margin-bottom: 2em;
}

.RtpPage__debug {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	max-width: 500px;
	flex: 1;
	padding: 10px;
}

.Debug p {
	margin: 0;
	font-size: 12px;
	line-height: 2;
}

.SyncButton {
	min-width: 140px;
	padding: 14px 20px;
	font-size: 14px;
	font-weight: 500;
	border-radius: 100px;
	background: var(--yellow);
	box-shadow: 1px 2px 14px hsl(50, 90%, 20%, 0.05);
}
.SyncButton:hover {
	box-shadow: 1px 2px 14px hsl(50, 90%, 20%, 0.1);
}
