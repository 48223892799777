.BaseButton {
	display: inline-block;
	appearance: none;
	border: none;
	font-family: inherit;
	cursor: pointer;
	text-decoration: none;
	outline: none;
	padding: 0;
	margin: 0;
	-webkit-tap-highlight-color: transparent;
}

.BaseButton:focus {
	outline: none;
}
.BaseButton:focus-visible {
	outline: 2px auto black;
}
.BaseButton:active {
	animation: button-click 100ms ease;
}

@keyframes button-click {
	50% {
		transform: scale(0.98);
	}
}

.Button {
}
.Button:hover {
}

.Link {
	appearance: none;
	padding: 0;
	margin: 0;
	background: transparent;
	text-decoration: none;
	color: inherit;
	font-weight: inherit;
	font-size: inherit;
	font-family: inherit;
	line-height: inherit;
	border: none;
	cursor: pointer;
	text-align: inherit;
}
.Link:hover {
	text-decoration: underline;
}
.Link--is-obvious {
	text-decoration: underline;
	opacity: 0.9;
}
.Link--is-obvious:hover {
	text-decoration: underline;
	opacity: 1;
}


.RoundButton {
	border-radius: 50%;
	margin: 0 auto;
	position: relative;
	background: var(--gold);
	font-size: 3vw;
	box-shadow: 1px 3px 30px 10px hsl(0, 70%, 60%, 0.2);
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	text-transform: uppercase;
	overflow: hidden;
	border: 3px solid transparent;
}
.RoundButton:before {
	content: '';
	display: block;
	padding-bottom: 100%;
}
.RoundButton:hover {
	transform: scale(1.05);
	transition-duration: 1s;
	box-shadow: 1px 3px 30px 10px hsl(0, 70%, 60%, 0.5);
}
.RoundButton:focus-visible {
	outline: none;
	border-color: black;
}
.RoundButton:active {
	animation: round-button-click 100ms ease;
}
@keyframes round-button-click {
	50% {
		transform: scale(1.03);
	}
}

.RoundButton__content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.RoundButton[data-icon] .RoundButton__content {
	background-size: 40% auto;
	background-position: center center;
	background-repeat: no-repeat;
}
.RoundButton[data-icon='play'] .RoundButton__content {
	background-image: url('~/assets/img/play.svg');
}
.RoundButton[data-icon='pause'] .RoundButton__content {
	background-image: url('~/assets/img/pause.svg');
}
.RoundButton[data-icon='sync'] .RoundButton__content {
	background-image: url('~/assets/img/sync.svg');
}

.ActionButton {
	padding: 14px 20px;
	font-size: 14px;
	font-weight: 500;
	border-radius: 100px;
	background: var(--yellow);
	box-shadow: 1px 2px 14px hsl(50, 90%, 20%, 0.05);
}
.ActionButton:hover {
	box-shadow: 1px 2px 14px hsl(50, 90%, 20%, 0.1);
}
