:root {
	--black: #000;
	--white: #fff;
	--blue: #ACFAFF;
	--green: #A6E489;
	--orange: hsl(39, 100%, 50%);
	--gold: hsl(51, 100%, 50%);
	--yellow: hsl(48, 100%, 50%);
}

html, body {
	margin: 0;
	padding: 0;
	background: var(--white);
	color: var(--black);
}

html {
	height: -webkit-fill-available;
}

body {
	font-family: 'Helvetica Neue', sans-serif;
	height: -webkit-fill-available;
	position: relative;
	touch-action: pan-y;
}

:global(#app) {
	min-height: 100vh;
	min-height: -webkit-fill-available;
	display: flex;
	align-items: stretch;
	justify-content: stretch;
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1;
	margin: 0;
	padding: 0;
	font-weight: normal;
}

a {
	color: inherit;
}

p {
	margin: 1em 0;
}
p + p {
	margin-top: 0em;
}


audio {
	position: absolute;
	top: 1%;
	left: 5%;
	width: 90%;
}
