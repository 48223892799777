.MediaPage {
	display: flex;
	flex-direction: column;
}

.Film__skewLabelEarly,
.Film__skewLabelLate,
.Film__skewLabel,
.Film__syncLabel {
	font-size: 16px;
	margin: 1.25em auto 0.75em;
	font-weight: bold;
}


.Film {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: stretch;
	justify-content: center;
	position: relative;
	z-index: 1;
}

.Film__title {
	margin: 1em auto auto;
	line-height: 0.8em;
	font-size: 45px;
	font-weight: 500;
}

.Film__playButton {
	width: 80%;
}
.Film__syncButton {
	width: 30%;
}
.Film__syncLabel {
	font-size: 18px;
}
.Film__player {
	margin: 0 auto auto;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.Film__skewControl {
	margin: 50px auto auto;
	align-self: stretch;
	display: flex;
	justify-content: space-around;
	align-items: center;
	max-width: 80%;
	flex-wrap: wrap;
}
.Film__skewLabel {
	width: 100%;
	text-align: center;
}
.Film__skewOutput {
	font-size: 18px;
	display: block;
	flex: 1;
	min-width: 100px;
	text-align: center;
}
.Film__skewLabelEarly,
.Film__skewLabelLate {
	width: 50%;
	font-size: 12px;
	margin: 0.25em 0 0;
	padding: 0 0.5em 1em;
	box-sizing: border-box;
}
.Film__skewLabelEarly {
	text-align: left;
}
.Film__skewLabelLate {
	text-align: right;

}


.Film__pauseControl {
	margin-top: 2em;
}

.Film__footer {
	margin-top: auto;
	margin-bottom: 2em;
}

.MediaPage__debug {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	max-width: 500px;
	flex: 1;
	padding: 10px;
	z-index: 100;
	pointer-events: none;
}

.MediaPage__debugToggle {
	font-size: 25px;
	text-align: center;
}

.Debug p {
	margin: 0;
	font-size: 12px;
	line-height: 2;
	word-break: break-all;
}

.EndScreen {
	text-align: center;
	font-size: 20px;
	padding-top: 50px;
}
.EndScreen__wait {
	animation: fade-in 500ms ease 2s both;
}
.EndScreen button {
	border-radius: 5px;
	border: none;
	font-size: 16px;
	padding: 15px 25px;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
