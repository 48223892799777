.Page {
	background: var(--orange);
	flex: 1;
}
@media (min-width: 361px) and (min-height: 621px) {
	.Page {
		flex: none;
		margin: auto;
		width: 360px;
		height: 620px;
		align-self: center;
		border-radius: 15px;
		box-shadow: 1px 5px 25px hsl(30, 90%, 20%, 0.2);
	}
}

.ErrorPage {
	background: #DD4444;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	line-height: 1.4;
}
.ErrorPage p {
	max-width: 80%;
}

.LoadingPage {}
